import React from "react";
import { navigate } from "gatsby";

const HomePage = (props) => {
	if (typeof window !== "undefined") {
		navigate(`${props.pathContext.isocode}`);
	}

	return null;
};

export default HomePage;
